<template>
  <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
</template>
<script>
import { GET_ITEMS, LOADING } from "@/core/services/store/REST.module";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import moment from "moment";
export default {
  name: "PurchasingInstructionReportChart",
  props: [
    "startDate",
    "endDate",
    "supplierCompanyId",
    "onClickButton",
    "instructionType",
    "fabricModels",
    "fabricType",
    "expenseId",
  ],
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    chartOptions() {
      let tmpChartOptions = {
        chart: {
          type: "bar",
          height: 500,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: _.cloneDeep(this.chartCategories),

          labels: {
            hideOverlappingLabels: true,
            trim: false,
            minHeight: 200,
            rotate: -90,
            style: {
              fontSize: "12px",
              display: "inline-block",
              overflow: "hidden",
              fontWeight: 500,
            },
            title: {
              text: undefined,
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
        yaxis: {
          title: {
            text: _.cloneDeep(this.instructionText),
          },
          labels: {
            formatter: (val) => {
              return val.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            },
          },
          formatter: function (val) {
            return val.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            },
          },
        },
      };
      return tmpChartOptions;
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartCategories: [],

      tmpSeries: [],
      series: [],
      instructionText: "",
    };
  },

  methods: {
    getItems() {
      this.series = [];
      this.chartCategories = [];
      self.chartCategories = [];
      this.series = [];
      let filters = {
        start_date: this.startDate,
        end_date: this.endDate,
        supplier_company_id: this.supplierCompanyId,
        instruction_type: this.instructionType,
        fabric_type: this.fabricType,
        expense_id: this.expenseId,
      };
      if (this.fabricType) {
        let findItem = _.find(this.fabricModels, { id: +this.fabricType });
        filters.fabric_type = findItem.class_name;
      }
      this.$set(
        filters,
        "start_date",
        moment(this.startDate).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "end_date", moment(this.endDate).format(LARAVEL_DATE_FORMAT));

      this.$store
        .dispatch(GET_ITEMS, {
          filters: filters,
          url: "api/report/purchasing-instruction-chart-data",
        })
        .then((result) => {
          let invoiceInstructionNetAmount = [];
          let tmpData = result.data;
          let self = this;
          _.forEach(tmpData, function (amount, key) {
            self.chartCategories.push(key);
            let sumInstructionNetAmount = 0;

            sumInstructionNetAmount += Number(amount);
            self.instructionText = "Toplam ";

            invoiceInstructionNetAmount.push(sumInstructionNetAmount.toFixed(4));
          });

          let tmpPackingSeries = {
            name: this.$t("general.total") + ": ",
            data: invoiceInstructionNetAmount,
          };
          this.series.push(tmpPackingSeries);
        });
    },
  },
  watch: {
    onClickButton: function (newVal, oldVal) {
      if (newVal == true) {
        this.getItems();
      }
    },
  },
};
</script>
